import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import AuthContext from 'src/contexts/AuthContext';
import { Loading } from 'src/components/DataState';
import { Notice } from 'src/elements';
import {
  FormContainer,
  Fields,
  defaultValues,
  EMAIL_PATTERN
} from 'src/elements/forms';
import { formatAuthErrors } from 'src/utils/errorHelpers';

import {
  Container,
  InnerContainer,
  Title,
  Button,
  Actions,
  ActionLink,
} from './elements';

import {
  BUYER_USER_STATUS,
  CREATE_BUYER_USER,
  LOGIN,
  SEND_PASSWORD_RESET,
} from './queries';

const REGISTER_FIELDS = {
  email: {
    rules: {
      required: true,
      pattern: { value: EMAIL_PATTERN, message: 'Enter a valid email' },
    },
    inputProps: { placeholder: 'Email' }
  },
  firstName: { rules: { required: true } },
  lastName: { rules: { required: true } },
  companyName: { label: 'Company Name (optional)' },
};

const FIELDS = {
  ...REGISTER_FIELDS,
  password: {
    rules: { required: true },
    inputProps: { type: 'password', placeholder: 'Password' }
  },
};

const LOGIN_FIELDS = {
  email: FIELDS.email,
  password: FIELDS.password,
};

const RegisterLogin = () => {
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const navigate = useNavigate();

  const {
    updateCredentials,
    rememberMe,
    updateRememberMe,
    refetchCurrentUser,
  } = useContext(AuthContext);

  const { control, handleSubmit, watch, reset, formState } = useForm({
    defaultValues: {
      ...defaultValues({ fields: FIELDS }),
      email: rememberMe || ''
    }
  });

  const watchEmail = watch('email');

  const { errors } = formState;

  useEffect(() => {
    if (!watchEmail && rememberMe) {
      reset({ email: watchEmail });
    }
  }, [rememberMe, reset, watchEmail]);

  const handleRememberMe = value => {
    if (value && watchEmail) {
      updateRememberMe(watchEmail);
    } else {
      updateRememberMe('');
    }
  }

  const [loadBuyerUserStatus, { data }] = useLazyQuery(BUYER_USER_STATUS);

  const [login] = useMutation(LOGIN, {
    onCompleted: data => {
      updateCredentials(data.buyerUserLogin.credentials);
      refetchCurrentUser();
      navigate('/');
    },
    onError: error => {
      setErrorMessage(formatAuthErrors(error.graphQLErrors));
      setSubmitting(false);
    }
  });

  const [createBuyerUser] = useMutation(CREATE_BUYER_USER, {
    onCompleted: data => {
      if (data.createBuyerUserWeb.buyerUser) {
        setSubmitting('success');
      }
    }
  });

  const [sendPasswordReset] = useMutation(SEND_PASSWORD_RESET, {
    onCompleted: () => setSubmitting('reset-sent')
  });

  const onSubmit = input => {
    setSubmitting(true);
    setErrorMessage(null);
    createBuyerUser({ variables: input });
  };

  const onCheckStatus = input => {
    loadBuyerUserStatus({ variables: { email: input.email }});
  };

  const onLogin = input => {
    setSubmitting(true);
    setErrorMessage(null);
    login({ variables: { email: input.email, password: input.password } });
  };

  const onPasswordReset = input => {
    sendPasswordReset({ variables: { email: watchEmail } });
  };

  let content = (
    <FormContainer onEnter={handleSubmit(onCheckStatus)}>
      <Fields
        fields={{ email: REGISTER_FIELDS.email }}
        control={control}
        errors={errors}
      />
      <Button onClick={handleSubmit(onCheckStatus)}>Next</Button>
    </FormContainer>
  );

  if ('success' === submitting) {
    content = (
      <Notice type="success">
        Check your email for next steps.
      </Notice>
    );
  } else if ('reset-sent' === submitting) {
    content = (
      <Notice type="success">
        Check your email for a link to reset your password.
      </Notice>
    );
  } else if (submitting) {
    content = <Loading />;
  } else if ('available' === data?.buyerUserStatus) {
    content = (
      <FormContainer onEnter={handleSubmit(onSubmit)}>
        <Fields fields={REGISTER_FIELDS} control={control} errors={errors} />
        <Button onClick={handleSubmit(onSubmit)}>Register</Button>
      </FormContainer>
    )
  } else if ('hidden' === data?.buyerUserStatus) {
    content = (
      <Notice type="error">
        There was an error accessing your account.
      </Notice>
    );
  } else if ('invited' === data?.buyerUserStatus) {
    content = (
      <>
        <Notice type="warning">
          Your email has not been confirmed.
        </Notice>
        <Button onClick={onPasswordReset}>Resend Email</Button>
      </>
    );
  } else if ('confirmed' === data?.buyerUserStatus) {
    content = (
      <>
        <FormContainer onEnter={handleSubmit(onLogin)}>
          <Fields fields={LOGIN_FIELDS} control={control} errors={errors} />
          <Button onClick={handleSubmit(onLogin)}>Login</Button>
        </FormContainer>

        <Actions>
          <div>
            <input type="checkbox"
              checked={rememberMe}
              onChange={e => handleRememberMe(e.target.checked)}
            />
            <ActionLink>Remember Me</ActionLink>
          </div>
          <div>
            <ActionLink onClick={onPasswordReset}>
              Send Password Reset Email
            </ActionLink>
          </div>
        </Actions>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Register/Login</title>
      </Helmet>
      <Container>
        <InnerContainer>
          <Title>Register/Login</Title>
          {errorMessage && <Notice type="error">{errorMessage}</Notice>}
          {content}
        </InnerContainer>
      </Container>
    </>
  );
};

export default RegisterLogin;
