import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { ActivityIndicator, View } from 'react-native';

import ErrorBoundary from './components/ErrorBoundary';
import { AuthProvider, AuthContext } from './contexts/AuthContext';
import { MessageInputProvider } from './contexts/MessageInputContext';
import { ResourcesProvider } from './contexts/ResourcesContext';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import Main from './Main';
import Auth from './components/Auth';

const App = () => {
  return (
    <HelmetProvider>
      <ErrorBoundary>
        <AuthProvider>
          <MessageInputProvider>
            <ResourcesProvider>
              <AuthContext.Consumer>
                  {ctx => ctx.apolloClient ? (
                    <ErrorBoundary>
                      {ctx.isLoggedIn || '/login' === window.location.pathname ?
                        <Main /> :
                        <Auth />
                      }
                    </ErrorBoundary>
                  ) : (
                    <View>
                      <ActivityIndicator size="large" />
                    </View>
                  )}
              </AuthContext.Consumer>
            </ResourcesProvider>
          </MessageInputProvider>
        </AuthProvider>
      </ErrorBoundary>
    </HelmetProvider>
  );
};

export default App;
