import React, { createContext, useCallback, useEffect, useState } from 'react';
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
  from,
  split,
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import * as ActionCable from '@rails/actioncable';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';

import config from 'src/config';
import storage from 'src/utils/storage';
import { sendError } from 'src/components/ErrorBoundary';
import { CURRENT_USER } from 'src/components/Auth/queries';

const IGNORE_ERRORS = ['USER_ERROR', 'NOT_FOUND'];

const APP_SHORT_NAME = config.app.name.split('-')[1];
const VERSION_KEY = 'X-Equiplinc-Version';
const VERSION = process.env.REACT_APP_VERSION;
const REMEMBER_ME_KEY = 'remember-me';

export const AuthContext = createContext();

const cable = ActionCable.createConsumer(config.api.baseUri + '/cable');
const actionCableLink = new ActionCableLink({ cable });

const setupApolloClient = ({ credentials, checkVersion, clearCredentials }) => {
  let headers = {};

  if (credentials) {
    headers = {
      ...credentials,
      'access-token': credentials.accessToken,
    };
  }

  const httpLink = new HttpLink({
    headers,
    uri: config.api.baseUri + '/web_graphql'
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription';
    },
    actionCableLink,
    httpLink,
  );

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (networkError) {
      if (401 === networkError.statusCode) {
        clearCredentials('/login');
      }
    } else if (graphQLErrors && graphQLErrors[0]) {
      if ('Not authorized' === graphQLErrors[0].message) {
        clearCredentials('/login');
      } else if (!IGNORE_ERRORS.includes(graphQLErrors[0].extensions?.code)) {
        sendError({
          error: {
            name: 'GraphQL Error',
            message: graphQLErrors[0].message,
            stack: JSON.stringify(graphQLErrors),
          }
        });
      }
    }
  });

  const checkHeadersLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(data => {
      const context = operation.getContext();

      if ('undefined' !== typeof(localStorage)) {
        if (context.response?.headers) {
          localStorage.setItem(
            'X-Request-Id',
            context.response.headers.get('X-Request-Id')
          );

          checkVersion(context.response.headers.get(VERSION_KEY));
        }
      }

      return data;
    });
  });

  return new ApolloClient({
    link: from([checkHeadersLink, errorLink, splitLink]),
    cache: new InMemoryCache(),
  });
};

export const AuthProvider = ({ children }) => {
  const [apolloClient, setApolloClient] = useState();
  const [clientHasCredentials, setClientHasCredentials] = useState(false);
  const [credentials, setCredentials] = useState();
  const [currentUser, setCurrentUser] = useState();
  const [rememberMe, setRememberMe] = useState();
  const [newVersion, setNewVersion] = useState(false);
  const [ignoreVersion, setIgnoreVersion] = useState(false);
  const [checkedCredentials, setCheckedCredentials] = useState(false);

  const updateCredentials = value => {
    const data = { credentials: value };

    storage.save({ key: 'auth', data }).then(() => {
      if (credentials?.accessToken !== value?.accessToken) {
        setCredentials(value);
      }
    });

    if (!value) {
      setCurrentUser(null);
    }
  };

  const clearCredentials = path => {
    updateCredentials(null);
    setCurrentUser(null);
    setCredentials(null);
    window.location.href = (path || '/');
  };

  useEffect(() => {
    const checkVersion = versionsFromAPI => {
      if (!VERSION) { return; }
      if (!versionsFromAPI) { return; }

      if (ignoreVersion && (
        new Date().getTime() > ignoreVersion + (60 * 60 * 1000)
      )) {
        setIgnoreVersion(false);
      }

      let versionFromAPI;

      versionsFromAPI.split(';').forEach(value => {
        if (APP_SHORT_NAME === value.split(':')[0]) {
          versionFromAPI = value.split(':')[1]
        }
      });

      if (!versionFromAPI) { return; }

      if (parseInt(versionFromAPI) > VERSION) {
        // TODO Change to true to re-enable yellow bar
        if (!newVersion) { setNewVersion(false); }
      } else {
        if (newVersion) { setNewVersion(false); }
      }
    };

    setApolloClient(setupApolloClient({
      credentials, checkVersion, clearCredentials
    }));

    if (credentials?.accessToken) {
      setClientHasCredentials(true);
    }
  }, [credentials, ignoreVersion, newVersion]);

  useEffect(() => {
    storage.load({ key: 'auth' }).then(authData => {
      if (authData?.credentials) {
        setCredentials(authData.credentials);
      }
    });

    storage.load({ key: REMEMBER_ME_KEY }).then(values => {
      setRememberMe(values?.email);
    });

    setCheckedCredentials(true);
  }, []);

  const updateRememberMe = email => {
    storage.save({ key: REMEMBER_ME_KEY, data: { email } }).then(() => {
      if (rememberMe !== email) {
        setRememberMe(email);
      }
    });
  };

  const refetchCurrentUser = useCallback(() => {
    if (apolloClient && clientHasCredentials) {
      apolloClient.query({ query: CURRENT_USER }).then(({ data }) => {
        setCurrentUser(data.currentBuyerUser)
        if (!data.currentBuyerUser) {
          setCredentials(null);
        }
      });
    }
  }, [apolloClient, clientHasCredentials]);

  const isLoggedIn = !!credentials?.accessToken;

  if (!currentUser && isLoggedIn) {
    refetchCurrentUser();
  }

  const updateRegisteredSales = () => {
    return currentUser?.registeredSales;
  }

  const value = {
    apolloClient,
    updateCredentials,
    isLoggedIn,
    logout: clearCredentials,
    currentUser,
    refetchCurrentUser,
    rememberMe,
    updateRememberMe,
    showNewVersion: (newVersion && !ignoreVersion),
    setIgnoreVersion,
    checkedCredentials,
    registeredSales: currentUser?.registeredSales,
    updateRegisteredSales: updateRegisteredSales,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
