import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Home from 'src/components/Pages/Home';
import Inventory from 'src/components/Inventory';
import Asset from 'src/components/Inventory/Asset';
import Contact from 'src/components/Contact';

import Locations from 'src/components/Locations';

import Sales from 'src/components/Sales';
import Sale from 'src/components/Sales/show';
import BiddingSale from 'src/components/Bidding/Sale';

import Login from 'src/components/Auth/new';
import Profile from 'src/components/Account/Profile';
import UpdatePassword from 'src/components/Account/Password';
import UpdateBuyer from 'src/components/Account/Buyer';
import UpdatedBuyer from 'src/components/Account/BuyerUpdated';
import About from 'src/components/Pages/About';
import ConfirmSeller from 'src/components/ConfirmSeller/ConfirmSeller';

import Sell from 'src/components/Sell';

import useSubscriptions from 'src/hooks/useSubscriptions';

const PrimaryRoutes = () => {
  useSubscriptions()
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/inventory" element={<Inventory />} />
      <Route exact path="/inventory/watch" element={<Inventory watching />} />
      <Route exact path="/inv" element={<Inventory />} />
      <Route exact path="/buyNow" element={<Inventory buyNow />} />
      <Route exact path="/inventory/:id/:slug" element={<Asset />} />
      <Route exact path="/inventory/:id/:slug/:buyNow" element={<Asset />} />
      <Route exact path="/inv/specs/:soarrId/:slug" element={<Asset />} />
      <Route exact path="/bidding/:id" element={<BiddingSale />} />
      <Route exact path="/locations" element={<Locations />} />
      <Route exact path="/sales" element={<Sales />} />
      <Route exact path="/sales/:id" element={<Sale />} />
      {/* <Route exact path="/sales/:id/inventory" element={<Inventory />} /> */}
      <Route exact path="/sales/:saleId/inventory/:id/:slug" element={<Asset />} />
      <Route exact path="/contact" element={<Contact />} />
      <Route exact path="/contact/sellerId=:sellerId" element={<Contact />} />
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/account/profile" element={<Profile />} />
      <Route exact path="/account/password" element={<UpdatePassword />} />
      <Route exact path="/account/billing" element={<UpdateBuyer />} />
      <Route exact path="/account/billing-updated" element={<UpdatedBuyer />} />
      <Route exact path="/contact/assetId=:assetId" element={<Contact />} />
      <Route exact path="/about" element={<About />} />
      <Route exact path="/sell" element={<Sell />} />
      <Route exact path="/confirm-seller/:sellerId" element={<ConfirmSeller />} />
    </Routes>
  );
};

export default PrimaryRoutes;
