import React, { useState } from "react";
import ReactPlayer from "react-player";

const VideoPlayer = (video) => {
  const [showVideo, setShowVideo] = useState(false);

  return (
    <div
      onClick={() => setShowVideo(true)}
      className='react-player-wrapper'
    >
      <ReactPlayer
        url={video?.video['url']}
        playing={showVideo}
        className='react-player'
        width='100%'
        config={{
          youtube: {
            playerVars: {
              videoId: video?.video['youtubeVideoId'],
              origin: window.location.origin
            }
          }
        }}
      />
    </div>
  )

}

export default VideoPlayer;